<script>

/**
 * Agent Financial Info component
 */

import Choices from 'choices.js';

import SupplierAutoComplete from '@/components/supplier-auto-complete-input.vue'

export default {



    props: {
        agent: {
            type: Object,
            default: () => ({
                fields: {
                    financial: {}
                }
            })
        },

        financial: {
            type: Object,
            default: () => ({
            })
        },

        garnishee: {
            type: Array,
            default: () => ([])
        },

        del_fields : {
            type: Array,
            default: () => ([])
        }
    },
    components: {
        SupplierAutoComplete
    },


    data() {

        return {
            charge_choice: {}
        }

    },

    compute: {

       

    },

    validations: {


        agent: {

        }


    },



    methods: {



        reset() {
            this.$v.$reset();
        },

        validate() {
            this.$v.$touch();
            return this.$v.$invalid
        },


        update_agent(agent) {
            this.agent.payment_type = agent.payment_type
            this.agent.charge_type = agent.charge_type
            this.update_choice();
        },


        update_choice() {
            let charge_type_arr = [
                { label: 'Cheque', value: 'CHEQUE', selected: this.agent.charge_type == 'CHEQUE' ? true : false },
                { label: 'PAD', value: 'PAD', selected: this.agent.charge_type == 'PAD' ? true : false },
                { label: 'Credit Card', value: 'CREDIT_CARD', selected: this.agent.charge_type == 'CREDIT_CARD' ? true : false },
                { label: 'E-Transfer', value: 'ETRANSFER', selected: this.agent.charge_type == 'ETRANSFER' ? true : false },
            ]


            if (this.charge_choice) {
                this.charge_choice.clearStore()
                this.charge_choice.setChoices(charge_type_arr)
            }

            let payment_type_arr = [
                { label: 'Cheque', value: 'CHEQUE', selected: this.agent.payment_type == 'CHEQUE' ? true : false },
                { label: 'Direct Deposit', value: 'EFT', selected: this.agent.payment_type == 'EFT' ? true : false },
            ]


            if (this.payment_choice) {
                this.payment_choice.clearStore()
                this.payment_choice.setChoices(payment_type_arr)
            }
        },


        add_garnishee() {
            this.garnishee.push({
                field   : '',
                value   : '',
                portion : '',
                amount  : '',
            })
        },

        remove_garnishee(idx) {
            let d =  this.garnishee[idx]
            this.garnishee.splice(idx, 1)

            
            this.del_fields.push({
                field : d.field,
                tag   : 'garnishee'
            })
        },

        onSelectSupplier(event, g) {

            g.field         = event.supplier_code
            g.value         = event.id+'_'+event.name+'_'+Number(g.amount ||0)+'_'+Number(g.portion ||0)

            g.supplier_code = event.supplier_code
            g.supplier_id   = event.id
            g.supplier_name = event.name
            g.amount        = Number(g.amount ||0)
            g.portion       = Number(g.portion ||0)

        }

    },

    created() {



    },

    mounted() {
        this.charge_choice = new Choices('#charge_type', {});
        this.payment_choice = new Choices('#payment_type', {});

        this.update_choice();

        this.garnishee.map(e => {
            let v = e.value.split("_")
            e.supplier_id   = v.length > 0 ? v[0] : ''
            e.supplier_code = v.length > 1 ? v[1] : ''
            e.amount        = v.length > 2 ? v[2] : 0
            e.portion       = v.length > 3 ? v[3] : 0
        })
    }

};
</script>


<template>
    <div class="row">

        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Payment Type for Agent Billing</h5>
                </div>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="contact-info-email-input">Type:</label>
                            <select id="charge_type" v-model="agent.charge_type" class="form-control"></select>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="agent.charge_type == 'PAD'">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Bank Name:</label>
                                <input type="text" class="form-control" v-model="financial.charge_bank_name" />
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Financial Institute #:</label>
                                <input type="text" class="form-control" v-model="financial.charge_institute_number"
                                    maxlength="5" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Transit Number #:</label>
                                <input type="text" class="form-control" v-model="financial.charge_transit_number"
                                    maxlength="3" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Account Number #:</label>
                                <input type="text" class="form-control" v-model="financial.charge_account_number" />
                            </div>
                        </div>
                    </div>
                </div>


                <!-- credit card-->
                <div class="row" v-if="agent.charge_type == 'CREDIT_CARD'">

                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Card Type :</label>
                                <select id="credit_type" v-model="financial.charge_credit_card_type"
                                    class="form-control">
                                    <option value="VISA">VISA</option>
                                    <option value="MASTERCARD">MASTERCARD</option>
                                    <option value="AMEX">AMEX</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Name on Card :</label>
                                <input type="text" class="form-control" v-model="financial.charge_credit_card_name" />
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Credit Card Number :</label>
                                <input type="text" class="form-control" v-model="financial.charge_credit_card_number" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Expiration Date :</label>
                                <input type="text" class="form-control"
                                    v-model="financial.charge_credit_card_expiry_date" placeholder="MMYYYY"
                                    maxlength="6" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">CVC :</label>
                                <input type="text" class="form-control" v-model="financial.charge_credit_card_cvc"
                                    maxlength="5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end card -->


        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            02
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Deposit Type for Agent Commission</h5>
                </div>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="contact-info-email-input">Type:</label>
                            <select id="payment_type" v-model="agent.payment_type" class="form-control"></select>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="agent.payment_type == 'EFT'">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Bank Account Name:</label>
                                <input type="text" class="form-control" v-model="financial.pay_bank_name" />
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Institutional Number #:</label>
                                <input type="text" class="form-control" v-model="financial.pay_institute_number"
                                    maxlength="5" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Transit Number #:</label>
                                <input type="text" class="form-control" v-model="financial.pay_transit_number"
                                    maxlength="3" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Account Number #:</label>
                                <input type="text" class="form-control" v-model="financial.pay_account_number" />
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
        <!-- end card -->




        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            03
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Garnishee</h5>
                </div>


                <div class="flex-grow-1">
                    <div class="text-end">
                        <h5 class="card-title">
                            <button type="button" class="btn btn-primary w-sm" @click="add_garnishee"> Add Garnishee
                            </button>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="card-body">

                <div class="row" v-for=" (g, idx) in garnishee" :key="'garnishee_'+idx">
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="contact-info-email-input">Name</label>
                            <SupplierAutoComplete v-model="g.supplier_name" :query_type="'BROKERAGE'" :init_data="{style_class : 'form-control', supplier_code : g.supplier_name}" @onSelected="onSelectSupplier($event, g)"/>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="contact-info-email-input">Amount :</label>
                            <input type="text" class="form-control" v-model="g.amount" />
                        </div>
                    </div>

                    <div class="col-md-1">
                        <div class="mb-3">
                            <label for="contact-info-email-input">Portion(%) :</label>
                            <input type="text" class="form-control" v-model="g.portion"   v-mask="'##'"/>
                        </div>
                    </div>

                    <div class="col-md-7">
                        <div class="text-end mt-3">
                            <button type="button" class="btn btn-danger" @click="remove_garnishee(idx)">Delete</button>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <!-- end card -->



        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            04
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Other</h5>
                </div>


                <div class="flex-grow-1">
                    <div class="text-end">
                        <h5 class="card-title">
                           
                        </h5>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-2">
                        <label class="form-label">Commission Defer</label>
                        <b-form-select class="form-control" v-model="financial.is_defer" :options="[{text : 'Yes', value : '1'},{text : 'No', value : '0'}]"></b-form-select>
                    </div>

                    <div class="col-md-2">
                        <label class="form-label">GST Hold</label>
                        <b-form-select class="form-control" v-model="financial.is_gst_hold" :options="[{text : 'Yes', value : '1'},{text : 'No', value : '0'}]"></b-form-select>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>